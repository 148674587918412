@import "../../const/settings.scss";
.wp-nhakhoahub-story {
  width: 100%;
  background-color: #fff;
  .nhakhoahub-story {
    max-width: 1200px;
    width: 100%;
    margin: 60px auto;
    display: flex;
    align-items: center;
    gap: 50px;
    .wp-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      div {
        color: var(--Color-1, #2a3467);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 39px */
        text-transform: uppercase;
        margin-bottom: 23px;
      }
      p {
        color: var(--Color-4, #363636);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        margin-bottom: 23px;
      }
      .see-more-button {
        display: flex;
        width: 120px;
        height: 35px;
        padding: 12px 20px;
        flex-shrink: 0;
        border-radius: 5px;
        background: var(--Color-1, #2a3467) !important;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        align-items: center;
        border: none !important;
        span {
          margin-right: 5px;
          color: var(--Color-3, #fff);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .wp-image-story {
      .video-placeholder{
        position: relative;
        .play-button{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &:hover{
            filter: brightness(0.8);
            cursor: pointer;
          }
        }
      }
      width: 100%;
      flex: 1;
      .image-story {
        width: 100%;
        height: 100%;
        min-height: 323px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
}

.wp-nhakhoahub-reason {
  width: 100%;
  background-color: #f5f5f5;
  .nhakhoahub-reason {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      max-width: 600px;
      color: var(--Color-1, #2a3467);
      text-align: center;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 39px */
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    .sub-title {
      max-width: 600px;
      color: var(--Color-4, #363636);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      margin-bottom: 45px;
    }
    .wp-content {
      display: flex;
      gap: 40px;
      .item-content {
        flex: 1;
        width: 100%;
        // &:nth-child(2) {
        //   margin-top: calc(40px * 1);
        // }
        // &:nth-child(3) {
        //   margin-top: calc(40px * 2);
        // }
        // &:nth-child(4) {
        //   margin-top: calc(40px * 3);
        // }
        .wp-image-content {
          width: 100%;
          margin-bottom: 16px;
          height: 180px;
          .image-content {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .title-content {
          color: var(--Color-1, #2a3467);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 30px */
          margin-bottom: 12px;
        }
        p {
          color: var(--Color-4, #363636);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
          margin-bottom: 0;
        }
      }
    }
  }
}

@include media(lg) {
  .nhakhoahub-story {
    padding: 0 15px;
  }
  .nhakhoahub-reason {
    padding: 25px 15px !important;
  }
}

@include media(md) {
  .nhakhoahub-story {
    flex-direction: column;
    gap: 0px !important;
    .wp-content {
      align-items: center;
      div {
        font-size: 14px !important;
        margin-bottom: 13px;
        text-transform: none !important;
      }
      p {
        font-size: 12px !important;
        margin-bottom: 13px;
      }
      .see-more-button {
        width: 100px !important;
        height: 30px !important;
        padding: 7px 12px !important;
        margin-bottom: 30px !important;
        span {
          font-size: 12px !important;
        }
      }
    }
    .wp-image-story {
      // display: none;
      .image-story{
        aspect-ratio: 16/9 !important;
        min-height: auto !important;
      }
    }
  }
  .nhakhoahub-reason {
    .title {
      font-size: 14px !important;
      margin-bottom: 12px !important;
      text-transform: none !important;
    }
    .sub-title {
      font-size: 12px !important;
      margin-bottom: 12px !important;
    }
    .wp-content {
      .item-content {
        &:not(:first-of-type) {
          display: none;
        }
        .wp-image-content {
          height: 320px !important;
        }
        .title-content {
          font-size: 14px !important;
          // margin-bottom: 12px !important;
        }
        p {
          font-size: 12px !important;
        }
      }
    }
  }
}
