@import "../../const/settings.scss";
.dentistryItem {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  position: relative;
  .dentistryInfo {
    padding: 16px;
    display: flex;
    height: 100%;
    flex-direction: column;

    border-radius: 8px;
    background: #f5f5f5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .dentistryLogo {
      margin-right: 12px;
      margin-top: 8px;
      .img {
        object-fit: contain;
        width: 60px !important;
        height: 60px !important;
        position: static !important;
        border-radius: 100px;
        background-color: #fff;
      }
    }
    .dentistryDetails {
      flex-grow: 1;
      .coverContainer {
        display: flex;
        align-items: center;
        .dentistryName {
          text-transform: capitalize;
          font-weight: 600;
          color: #2a3467;
          font-size: 16px;
          margin-bottom: 8px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 1.35;
        }
      }
      .clinicAddress {
        color: var(--Color-4, #363636);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        text-transform: capitalize;
        margin-bottom: 22px;
      }
      .thirdLineTag {
        display: flex;
        align-items: center;
        // justify-content: space-around;
        .ratingPoint {
          display: flex;
          margin-left: 30px;
          .point {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #ffa800;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            padding: 3px 6px;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            border: 1px solid #ffa800;
          }
          .iconRatingStar {
            margin-left: 10px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    .buttonMobile {
      display: none;
    }
  }
}

@include media(lg) {
  .dentistryItem {
    .thirdLineTag {
      // display: none !important;
    }
    .buttonMobile {
      display: flex !important;
      .buttonAdvise {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 8px;
        border: 1px solid var(--Color-BrandColor, #00c5d7);
        height: 40px;
        padding: 0 20px;
        background-color: #fff;
        color: #00c5d7;

        /* P2/Bold */
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        cursor: pointer;
        margin-right: 8px;
      }
      .buttonDetail {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        text-align: center;
        background: var(--color-brand-color, #00c5d7);
        height: 40px;
        padding: 0 20px;
        color: #fff;

        /* P2/Bold */
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        cursor: pointer;
        // margin-right: 8px;
      }
    }
  }
}

@include media(md) {
  .dentistryItem {
    width: 100%;
    .dentistryInfo {
      padding: 24px 14px 14px 14px;
      .dentistryLogo {
        .img {
          height: 60px !important;
          width: 60px !important;
        }
      }
      .dentistryDetails {
        .coverContainer {
          h4 {
            margin-bottom: 0;
          }
        }
        .thirdLineTag {
          // display: none;
        }
      }
      .buttonMobile {
        display: flex;
        .buttonAdvise {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 8px;
          border: 1px solid var(--Color-BrandColor, #00c5d7);
          height: 40px;
          padding: 0 20px;
          color: #00c5d7;
          background-color: #fff;

          /* P2/Bold */
          font-size: 14px !important;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
          cursor: pointer;
          margin-right: 8px;
        }
        .buttonDetail {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          text-align: center;
          background: var(--color-brand-color, #00c5d7);
          height: 40px;
          padding: 0 20px;
          color: #fff;

          /* P2/Bold */
          font-size: 14px !important;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
          cursor: pointer;
          // margin-right: 8px;
        }
      }
    }
  }
}
